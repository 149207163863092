import React from "react";
import {
  Image,
  isExperienceEditorActive,
} from "@sitecore-jss/sitecore-jss-react";
import Link from "./Link";

const ImageContentLink = ({ imageField, linkField, ...props }) => {
  const imageElement = <Image field={imageField} {...props} />;

  return isExperienceEditorActive() || !linkField.value.href ? (
    imageElement
  ) : (
    <Link field={linkField}>{imageElement}</Link>
  );
};

export default ImageContentLink;
